import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import BackgroundImage from 'gatsby-background-image'
import styled from 'styled-components'
import { Row, Col } from 'reactstrap'

import { TopContainer, ArrowBottom } from './tags'

import ellipseIcon from '../images/ellipse.svg'

const StatisticsSection = styled.section`
  overflow: hidden;
`

const StatisticsContainer = styled(TopContainer)`
  position: relative;
  background-color: ${(props) => props.theme.colorWhite};
  padding-left: 135px;
  padding-right: 135px;
  padding-bottom: 80px;
  box-shadow: 0px 16px 58px 0px rgba(104, 45, 25, 0.13);
  @media (min-width: 1200px) and (max-width: 1599px) {
    padding-left: 90px;
    padding-right: 90px;
    padding-bottom: 40px;
    box-shadow: 0px 10px 36px 0px rgba(104, 45, 25, 0.13);
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    padding-left: 80px;
    padding-right: 80px;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    padding-left: 70px;
    padding-right: 70px;
    padding-bottom: 40px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    padding-left: 50px;
    padding-right: 50px;
    padding-bottom: 40px;
  }
  @media (max-width: 575px) {
    padding-left: 35px;
    padding-right: 35px;
    padding-bottom: 30px;
  }
`

const StatisticsWrapper = styled(Row)`
  background-color: ${(props) => props.theme.colorGray};
  padding-top: 60px;
  border-radius: 25px;
  @media (min-width: 1200px) and (max-width: 1599px) {
    padding-top: 40px;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    padding-top: 40px;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    padding-top: 40px;
    border-radius: 12px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    padding-top: 40px;
    border-radius: 24px;
  }
  @media (max-width: 575px) {
    padding-top: 25px;
    border-radius: 16px;
  }
`

const StatisticsTopDesc = styled.p`
  font-size: 28px;
  text-align: center;
  @media (min-width: 1200px) and (max-width: 1599px) {
    font-size: 18px;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    font-size: 18px;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    font-size: 16px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    font-size: 16px;
  }
  @media (max-width: 575px) {
    font-size: 14px;
  }
  strong {
    color: ${(props) => props.theme.colorOrange};
  }
  br {
    @media (max-width: 767px) {
      display: none;
    }
  }
`

const StatisticsArrowWrapper = styled.div`
  text-align: center;
  padding-top: 30px;
  padding-bottom: 30px;
  @media (min-width: 1200px) and (max-width: 1599px) {
    padding-top: 5px;
    padding-bottom: 15px;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    padding-top: 5px;
    padding-bottom: 15px;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    padding-top: 5px;
    padding-bottom: 15px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    padding-top: 5px;
    padding-bottom: 15px;
  }
  @media (max-width: 575px) {
    padding-top: 5px;
    padding-bottom: 15px;
  }
`

const StatisticsArrow = styled(ArrowBottom)``

const StatisticsItemWrapper = styled(Row)`
  position: relative;
  padding-left: 75px;
  padding-right: 75px;
  margin-bottom: 50px;
  @media (min-width: 1200px) and (max-width: 1599px) {
    padding-left: 40px;
    padding-right: 40px;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    padding-left: 40px;
    padding-right: 40px;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 20px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    padding-left: 35px;
    padding-right: 35px;
    margin-bottom: 20px;
  }
  @media (max-width: 575px) {
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 5px;
  }
  &:before,
  &:after {
    content: '';
    display: block;
    position: absolute;
    border-radius: 25px;
    @media (min-width: 768px) and (max-width: 991px) {
      border-radius: 12px;
    }
    @media (min-width: 576px) and (max-width: 767px) {
      border-radius: 24px;
    }
    @media (max-width: 575px) {
      border-radius: 16px;
    }
  }
  &:before {
    width: 300px;
    height: 200px;
    top: 245px;
    right: 29.4%;
    border-top: 3px solid ${(props) => props.theme.colorGrayDark};
    border-right: 3px solid ${(props) => props.theme.colorGrayDark};
    @media (min-width: 1200px) and (max-width: 1599px) {
      top: 172px;
      right: 28.5%;
      border-width: 2px;
    }
    @media (min-width: 992px) and (max-width: 1199px) {
      top: 172px;
      right: 29.1%;
      border-width: 2px;
    }
    @media (min-width: 768px) and (max-width: 991px) {
      height: 150px;
      top: 130px;
      right: 29%;
      border-width: 1px;
    }
    @media (max-width: 767px) {
      content: none;
    }
    /* @media (min-width: 576px) and (max-width: 767px) {
      height: 150px;
      top: 130px;
      right: 29%;
      border-width: 1px;
    } */
  }
  &:after {
    width: 500px;
    height: 200px;
    top: 435px;
    left: 70.3%;
    border-left: 3px solid ${(props) => props.theme.colorGrayDark};
    border-bottom: 3px solid ${(props) => props.theme.colorGrayDark};
    border-bottom-right-radius: 0;
    ${(props) =>
      props.position === 'middle' &&
      `
    width: 659px;
    height: 5px;
    top: 243px;
    left: -120px;
    border-left: none;
    border-bottom: 3px solid ${props.theme.colorGrayDark};
  `};
    @media (min-width: 1200px) and (max-width: 1599px) {
      width: 359px;
      top: 265px;
      left: 71.3%;
      border-width: 2px;
      ${(props) =>
        props.position === 'middle' &&
        `
        width: 659px;
        top: 169px;
        left: -75px;
      `};
    }
    @media (min-width: 992px) and (max-width: 1199px) {
      width: 243px;
      top: 265px;
      left: 70.8%;
      border-width: 2px;
      ${(props) =>
        props.position === 'middle' &&
        `
        width: 659px;
        top: 169px;
        left: -66px;
      `};
    }
    @media (min-width: 768px) and (max-width: 991px) {
      width: 232px;
      height: 150px;
      top: 187px;
      left: 71%;
      border-width: 1px;
      ${(props) =>
        props.position === 'middle' &&
        `
        width: 350px;
        top: -19px;
        left: -56px;
        border-radius: 0;
      `};
    }
    @media (max-width: 767px) {
      content: none;
    }
  }
`

const StatisticsItemRow = styled(Row)`
  position: relative;
  ${(props) =>
    props.side === 'left' &&
    `
    margin-left: 0;
  `};
  ${(props) =>
    props.side === 'right' &&
    `
    margin-right: 0;
  `};
`

const StatisticsItemTitleWrapper = styled(Col)`
  margin-bottom: -30px;
  @media (min-width: 1200px) and (max-width: 1599px) {
    margin-bottom: -40px;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    margin-bottom: -40px;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    margin-bottom: -40px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    margin-bottom: -50px;
  }
  @media (max-width: 575px) {
    margin-bottom: -50px;
  }
`

const StatisticsItemPerson = styled(Col)`
  position: relative;
  padding-left: 0;
  ${(props) =>
    props.side === 'left' &&
    `
    right: -12px;
  `};
  ${(props) =>
    props.side === 'right' &&
    `
    left: -12px;
    margin-top: 200px;
  `};
  @media (min-width: 1200px) and (max-width: 1599px) {
    ${(props) =>
      props.side === 'left' &&
      `
      right: -8px;
    `};
    ${(props) =>
      props.side === 'right' &&
      `
    left: -7px;
    margin-top: 140px;
  `};
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    ${(props) =>
      props.side === 'left' &&
      `
      right: -8px;
    `};
    ${(props) =>
      props.side === 'right' &&
      `
    left: -7px;
    margin-top: 140px;
  `};
  }
  @media (min-width: 768px) and (max-width: 991px) {
    ${(props) =>
      props.side === 'left' &&
      `
      right: -6px;
    `};
    ${(props) =>
      props.side === 'right' &&
      `
    left: -6px;
    margin-top: 105px;
  `};
  }
  @media (min-width: 576px) and (max-width: 767px) {
    ${(props) =>
      props.side === 'left' &&
      `
      right: 50px;
    `};
    ${(props) =>
      props.side === 'right' &&
      `
    left: 50px;
    margin-top: -80px;
  `};
  }
  @media (max-width: 575px) {
    ${(props) =>
      props.side === 'left' &&
      `
      right: 30px;
    `};
    ${(props) =>
      props.side === 'right' &&
      `    
    left: 30px;
    padding-left: 15px;
    margin-top: -50px;
  `};
  }
`

const StatisticsItemTitle = styled.h2`
  color: ${(props) => props.theme.colorOrange};
  font-size: 36px;
  font-weight: 700;
  text-align: center;
  @media (min-width: 1200px) and (max-width: 1599px) {
    font-size: 28px;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    font-size: 28px;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    font-size: 20px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    font-size: 20px;
  }
  @media (max-width: 575px) {
    font-size: 16px;
  }
  br {
    @media (min-width: 768px) {
      display: none;
    }
  }
`

const StatisticsItemColImage = styled(Col)`
  overflow: hidden;
  padding-top: 70px;
  ${(props) =>
    props.side === 'left' &&
    `
    padding-left: 0;
    padding-right: 0;
    border-bottom-left-radius: 25px;
  `};
  ${(props) =>
    props.side === 'right' &&
    `
    padding-left: 0;
    padding-right: 0;
    border-bottom-right-radius: 25px;
  `};
  @media (min-width: 1200px) and (max-width: 1599px) {
    padding-top: 70px;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    padding-top: 70px;
  }
  @media (min-width: 768px) {
    z-index: 100;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    padding-top: 50px;
    ${(props) =>
      props.side === 'left' &&
      `
      border-bottom-left-radius: 12px;
    `};
    ${(props) =>
      props.side === 'right' &&
      `
      border-bottom-right-radius: 12px;
    `};
  }
  @media (min-width: 576px) and (max-width: 767px) {
    padding-top: 80px;
    ${(props) =>
      props.side === 'left' &&
      `
      border-bottom-left-radius: 24px;
    `};
    ${(props) =>
      props.side === 'right' &&
      `
      border-bottom-right-radius: 24px;
    `};
  }
  @media (max-width: 575px) {
    padding-top: 60px;
    ${(props) =>
      props.side === 'left' &&
      `
      border-bottom-left-radius: 16px;
    `};
    ${(props) =>
      props.side === 'right' &&
      `
      border-bottom-right-radius: 16px;
    `};
  }
`

const StatisticsItemImageBGWrapper = styled.div`
  position: relative;
  height: 300px;
  ${(props) =>
    props.side === 'left' &&
    `
    background-color: ${props.theme.colorOrangeBlack};
    border-top-left-radius: 25px;
  `};
  ${(props) =>
    props.side === 'right' &&
    `
    background-color: ${props.theme.colorGold};
    border-top-right-radius: 25px;
  `};
  @media (min-width: 1200px) and (max-width: 1599px) {
    height: 220px;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    height: 220px;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    height: 160px;
    ${(props) =>
      props.side === 'left' &&
      `
      border-top-left-radius: 12px;
    `};
    ${(props) =>
      props.side === 'right' &&
      `
      border-top-right-radius: 12px;
    `};
  }
  @media (min-width: 576px) and (max-width: 767px) {
    height: 220px;
    ${(props) =>
      props.side === 'left' &&
      `
      border-top-left-radius: 24px;
    `};
    ${(props) =>
      props.side === 'right' &&
      `
      border-top-right-radius: 24px;
    `};
  }
  @media (max-width: 575px) {
    height: 152px;
    ${(props) =>
      props.side === 'left' &&
      `
      border-top-left-radius: 16px;
    `};
    ${(props) =>
      props.side === 'right' &&
      `
      border-top-right-radius: 16px;
    `};
  }
  @media (max-width: 340px) {
    height: 130px;
  }
`

const StatisticsItemImageBG = styled(BackgroundImage)`
  position: absolute !important;
  top: -70px;
  left: 0;
  right: 0;
  bottom: 0;
  background-position-x: center !important;
  background-position-y: top !important;
  background-size: cover !important;
  @media (min-width: 768px) and (max-width: 991px) {
    top: -50px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    top: -80px;
  }
  @media (max-width: 575px) {
    top: -60px;
  }
`

const StatisticsItemPersonColPercent = styled(Col)`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 70px;
  ${(props) =>
    props.side === 'left' &&
    `
    background-color: ${props.theme.colorGoldDark};
    border-top-left-radius: 25px;
    border-bottom-left-radius: 25px;
  `};
  ${(props) =>
    props.side === 'right' &&
    `
    background-color: ${props.theme.colorRedDark};
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
  `};
  @media (min-width: 1200px) and (max-width: 1599px) {
    margin-top: 70px;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    margin-top: 70px;
  }
  @media (min-width: 768px) {
    z-index: 100;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    margin-top: 50px;
    ${(props) =>
      props.side === 'left' &&
      `
      border-top-left-radius: 12px;
      border-bottom-left-radius: 12px;
    `};
    ${(props) =>
      props.side === 'right' &&
      `
      border-top-right-radius: 12px;
      border-bottom-right-radius: 12px;
    `};
  }
  @media (max-width: 767px) {
    position: relative;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    margin-top: 80px;
    ${(props) =>
      props.side === 'left' &&
      `
      border-top-left-radius: 24px;
      border-bottom-left-radius: 24px;
    `};
    ${(props) =>
      props.side === 'right' &&
      `
      border-top-right-radius: 24px;
      border-bottom-right-radius: 24px;
    `};
  }
  @media (max-width: 575px) {
    margin-top: 60px;
    ${(props) =>
      props.side === 'left' &&
      `
      border-top-left-radius: 16px;
      border-bottom-left-radius: 16px;
    `};
    ${(props) =>
      props.side === 'right' &&
      `
      border-top-right-radius: 16px;
      border-bottom-right-radius: 16px;
    `};
  }
  &:before,
  &:after {
    content: none;
    position: absolute;
    display: block;
    width: 45px;
    height: 300px;
    /* background-color: red; */
    @media (max-width: 767px) {
      content: '';
    }
    @media (max-width: 575px) {
      width: 25px;
    }
  }
  &:before {
    ${(props) =>
      props.side === 'right' &&
      `
      content: none;
    `};
    height: 110px;
    left: -45px;
    bottom: 50%;
    border-left: 3px solid ${(props) => props.theme.colorGrayDark};
    border-bottom: 3px solid ${(props) => props.theme.colorGrayDark};
    border-bottom-left-radius: 24px;
    z-index: 10;
    @media (min-width: 576px) and (max-width: 767px) {
      ${(props) =>
        props.side === 'left' &&
        `
        left: -35px;
        width: 35px;
      `};
    }
    @media (max-width: 575px) {
      height: 75px;
      left: -25px;
      border-left: 2px solid ${(props) => props.theme.colorGrayDark};
      border-bottom: 2px solid ${(props) => props.theme.colorGrayDark};
      border-bottom-left-radius: 16px;
    }
    @media (max-width: 340px) {
      height: 65px;
    }
  }
  &:after {
    ${(props) =>
      props.side === 'left' &&
      `
      content: none;
    `};
    top: 50%;
    right: -45px;
    border-top: 3px solid ${(props) => props.theme.colorGrayDark};
    border-right: 3px solid ${(props) => props.theme.colorGrayDark};
    border-top-right-radius: 24px;
    @media (max-width: 575px) {
      right: -25px;
      border-top: 2px solid ${(props) => props.theme.colorGrayDark};
      border-right: 2px solid ${(props) => props.theme.colorGrayDark};
      border-top-right-radius: 16px;
    }
  }
`

const StatisticsItemPersonPercentWrapper = styled.p`
  color: ${(props) => props.theme.colorWhite};
  line-height: 1;
`

const StatisticsItemPersonPercentAmount = styled.span`
  display: block;
  font-family: ${(props) => props.theme.fontHelveticaNeue};
  font-size: 104px;
  @media (min-width: 1200px) and (max-width: 1599px) {
    font-size: 78px;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    font-size: 78px;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    font-size: 54px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    font-size: 54px;
  }
  @media (max-width: 575px) {
    font-size: 48px;
  }
  @media (max-width: 340px) {
    font-size: 44px;
  }
`

const StatisticsItemPersonPercentDesc = styled.span`
  display: block;
  font-family: ${(props) => props.theme.fontHelveticaNeue};
  font-size: 35px;
  font-weight: 300;
  @media (min-width: 1200px) and (max-width: 1599px) {
    font-size: 22px;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    font-size: 22px;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    font-size: 18px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    font-size: 18px;
  }
  @media (max-width: 575px) {
    font-size: 16px;
  }
  @media (max-width: 340px) {
    font-size: 14px;
  }
  small {
    font-size: 26px;
    font-weight: 300;
    @media (min-width: 1200px) and (max-width: 1599px) {
      font-size: 17px;
    }
    @media (min-width: 992px) and (max-width: 1199px) {
      font-size: 17px;
    }
    @media (min-width: 768px) and (max-width: 991px) {
      font-size: 15px;
    }
    @media (min-width: 576px) and (max-width: 767px) {
      font-size: 15px;
    }
    @media (max-width: 575px) {
      font-size: 14px;
    }
    @media (max-width: 340px) {
      font-size: 12px;
    }
  }
`

const StatisticsItemPersonColDesc = styled(Col)`
  position: relative;
  padding-top: 25px;
  padding-left: 60px;
  padding-right: 60px;
  @media (min-width: 1200px) and (max-width: 1599px) {
    padding-top: 20px;
    padding-left: 35px;
    padding-right: 35px;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    padding-top: 20px;
    padding-left: 35px;
    padding-right: 35px;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    padding-top: 15px;
    padding-left: 30px;
    padding-right: 30px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    padding-top: 15px;
    padding-left: 30px;
    padding-right: 15%;
  }
  @media (max-width: 575px) {
    padding-top: 15px;
    padding-left: 30px;
    padding-right: 25px;
    ${(props) =>
      props.side === 'left' &&
      `
      padding-right: 20%;
    `};
  }
  &:before {
    content: '';
    display: block;
    width: 120px;
    height: 120px;
    position: absolute;
    top: -70px;
    left: -5px;
    background-image: url(${ellipseIcon});
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    z-index: 100;
    @media (min-width: 1200px) and (max-width: 1599px) {
      width: 90px;
      height: 90px;
      top: -53px;
      left: -12px;
    }
    @media (min-width: 992px) and (max-width: 1199px) {
      width: 90px;
      height: 90px;
      top: -53px;
      left: -12px;
    }
    @media (min-width: 768px) {
      z-index: 100;
    }
    @media (min-width: 768px) and (max-width: 991px) {
      width: 66px;
      height: 66px;
      top: -38px;
      left: -6px;
    }
    @media (min-width: 576px) and (max-width: 767px) {
      width: 66px;
      height: 66px;
      top: -38px;
      left: -6px;
    }
    @media (max-width: 575px) {
      width: 66px;
      height: 66px;
      top: -38px;
      left: -4px;
    }
  }
`

const StatisticsItemPersonDescTitle = styled.h3`
  font-size: 28px;
  font-weight: 300;
  @media (min-width: 1200px) and (max-width: 1599px) {
    font-size: 18px;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    font-size: 18px;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    font-size: 15px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    font-size: 15px;
  }
  @media (max-width: 575px) {
    font-size: 14px;
  }
  strong {
    font-weight: 700;
  }
`

const StatisticsItemPersonDesc = styled.p`
  font-family: ${(props) => props.theme.fontHelveticaNeue};
  font-size: 20px;
  font-weight: 300;
  @media (min-width: 1200px) and (max-width: 1599px) {
    font-size: 15px;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    font-size: 15px;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    font-size: 12px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    font-size: 12px;
  }
  @media (max-width: 575px) {
    font-size: 11px;
  }
`

const StatisticsCauses = styled(Row)`
  padding-left: 75px;
  padding-right: 75px;
  padding-bottom: 15px;
  @media (min-width: 1200px) and (max-width: 1599px) {
    padding-left: 35px;
    padding-right: 35px;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    padding-left: 35px;
    padding-right: 35px;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    padding-left: 15px;
    padding-right: 15px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    padding-top: 15px;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 50px;
  }
  @media (max-width: 575px) {
    padding-top: 20px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 45px;
  }
`

const StatisticsCausesImage = styled(Col)`
  @media (min-width: 1200px) and (max-width: 1599px) {
    flex: 0 0 25%;
    max-width: 25%;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    padding-right: 50%;
    padding-bottom: 25px;
  }
  @media (max-width: 575px) {
    padding-right: 25%;
    padding-bottom: 20px;
  }
`

const StatisticsCausesDesc = styled(Col)`
  font-size: 21px;
  font-weight: 300;
  @media (min-width: 1200px) and (max-width: 1599px) {
    flex: 0 0 75%;
    max-width: 75%;
    font-size: 14px;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    font-size: 14px;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    font-size: 11px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    font-size: 18px;
  }
  @media (max-width: 575px) {
    font-size: 13px;
  }
  p {
    @media (min-width: 768px) and (max-width: 991px) {
      margin-bottom: 10px;
    }
    @media (min-width: 576px) and (max-width: 767px) {
      margin-bottom: 30px;
    }
    @media (max-width: 575px) {
      margin-bottom: 20px;
    }
    &:last-of-type {
      margin-top: 30px;
      @media (min-width: 1200px) and (max-width: 1599px) {
        margin-top: 20px;
        margin-bottom: 5px;
      }
      @media (min-width: 992px) and (max-width: 1199px) {
        margin-top: 20px;
        margin-bottom: 5px;
      }
      @media (min-width: 768px) and (max-width: 991px) {
        margin-top: 10px;
        margin-bottom: 3px;
      }
      @media (min-width: 576px) and (max-width: 767px) {
        margin-top: 20px;
        margin-bottom: 3px;
      }
      @media (max-width: 575px) {
        margin-top: 20px;
        margin-bottom: 3px;
      }
    }
    strong {
      color: ${(props) => props.theme.colorOrange};
      font-weight: 700;
    }
    &.titleList {
      margin-bottom: 5px;
      @media (min-width: 1200px) and (max-width: 1599px) {
        margin-bottom: 4px;
      }
      @media (min-width: 992px) and (max-width: 1199px) {
        margin-bottom: 4px;
      }
      @media (min-width: 768px) and (max-width: 991px) {
        margin-bottom: 4px;
      }
      @media (min-width: 576px) and (max-width: 767px) {
        margin-bottom: 15px;
      }
      @media (max-width: 575px) {
        margin-bottom: 10px;
      }
    }
    &.list {
      font-weight: 700;
      margin-bottom: 0;
    }
  }
`

const StatisticsCausesDescTitle = styled.h2`
  color: ${(props) => props.theme.colorOrangeRed};
  font-size: 26px;
  font-weight: 700;
  margin-bottom: 35px;
  @media (min-width: 1200px) and (max-width: 1599px) {
    font-size: 17px;
    margin-bottom: 20px;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    font-size: 17px;
    margin-bottom: 20px;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    font-size: 14px;
    margin-bottom: 15px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    font-size: 20px;
    margin-bottom: 30px;
  }
  @media (max-width: 575px) {
    font-size: 15px;
    margin-bottom: 20px;
  }
`

const StatisticsNote = styled(Row)`
  padding-left: 35px;
  padding-right: 35px;
  margin-top: 60px;
  @media (min-width: 1200px) and (max-width: 1599px) {
    margin-top: 40px;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    margin-top: 40px;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    padding-left: 10px;
    padding-right: 10px;
    margin-top: 30px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    padding-left: 0;
    padding-right: 0;
    margin-top: 40px;
    margin-left: -30px;
    margin-right: -30px;
  }
  @media (max-width: 575px) {
    padding-left: 0;
    padding-right: 0;
    margin-top: 30px;
    margin-left: -30px;
    margin-right: -30px;
  }
`

const StatisticsNoteDesc = styled(Col)`
  font-size: 15px;
  font-weight: 300;
  @media (min-width: 1200px) and (max-width: 1599px) {
    font-size: 10px;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    font-size: 10px;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    font-size: 9px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    font-size: 14px;
  }
  @media (max-width: 575px) {
    font-size: 11px;
  }
  p {
    &:last-of-type {
      margin-top: 15px;
      @media (min-width: 1200px) and (max-width: 1599px) {
        margin-top: 10px;
        margin-bottom: 10px;
      }
      @media (min-width: 992px) and (max-width: 1199px) {
        margin-top: 10px;
        margin-bottom: 10px;
      }
      @media (min-width: 768px) and (max-width: 991px) {
        margin-top: 10px;
        margin-bottom: 10px;
      }
      @media (min-width: 576px) and (max-width: 767px) {
        margin-top: 10px;
        margin-bottom: 10px;
      }
      @media (max-width: 575px) {
        margin-top: 10px;
        margin-bottom: 10px;
      }
    }
    &.list {
      font-weight: 400;
      margin-bottom: 0;
    }
    span {
      display: block;
      font-weight: 400;
    }
  }
`

const Statistics = (props) => {
  const data = useStaticQuery(graphql`
    query StatisticsQuery {
      Photo1: file(relativePath: { regex: "/photo_1.png/" }) {
        childImageSharp {
          fluid(maxWidth: 300, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      Photo2: file(relativePath: { regex: "/photo_2.png/" }) {
        childImageSharp {
          fluid(maxWidth: 300, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      Photo3: file(relativePath: { regex: "/photo_3.png/" }) {
        childImageSharp {
          fluid(maxWidth: 300, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      Photo4: file(relativePath: { regex: "/photo_4.png/" }) {
        childImageSharp {
          fluid(maxWidth: 300, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      Photo5: file(relativePath: { regex: "/photo_5.png/" }) {
        childImageSharp {
          fluid(maxWidth: 300, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      Photo6: file(relativePath: { regex: "/photo_6.png/" }) {
        childImageSharp {
          fluid(maxWidth: 300, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      QuestionIcon: file(relativePath: { regex: "/question_icon.png/" }) {
        childImageSharp {
          fluid(maxWidth: 320, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <StatisticsSection>
      <StatisticsContainer>
        <StatisticsWrapper>
          <Col>
            <Row>
              <Col>
                <StatisticsTopDesc>
                  Mimo ogromnej skali problemu{' '}
                  <strong>świadomość Polaków</strong> na temat <br /> tej
                  choroby jest na bardzo niskim poziomie.
                </StatisticsTopDesc>
                <StatisticsArrowWrapper>
                  <StatisticsArrow />
                </StatisticsArrowWrapper>
              </Col>
            </Row>
            <StatisticsItemWrapper position={'first'}>
              <StatisticsItemTitleWrapper xs={12}>
                <StatisticsItemTitle>
                  Ze względu <br /> na PŁEĆ:
                </StatisticsItemTitle>
              </StatisticsItemTitleWrapper>
              <StatisticsItemPerson xs={12} md={6} side={'left'}>
                <StatisticsItemRow side={'left'}>
                  <StatisticsItemColImage xs={6} side={'left'}>
                    <StatisticsItemImageBGWrapper side={'left'}>
                      <StatisticsItemImageBG
                        fluid={data.Photo1.childImageSharp.fluid}
                        alt=""
                        backgroundColor={`transparent`}
                      />
                    </StatisticsItemImageBGWrapper>
                  </StatisticsItemColImage>
                  <StatisticsItemPersonColPercent xs={6} side={'right'}>
                    <StatisticsItemPersonPercentWrapper side={'right'}>
                      <StatisticsItemPersonPercentAmount>
                        83%
                      </StatisticsItemPersonPercentAmount>
                      <StatisticsItemPersonPercentDesc>
                        kobiet
                      </StatisticsItemPersonPercentDesc>
                    </StatisticsItemPersonPercentWrapper>
                  </StatisticsItemPersonColPercent>
                  <StatisticsItemPersonColDesc xs={12} side={'left'}>
                    <StatisticsItemPersonDescTitle>
                      <strong>Ola</strong> 33 lata
                    </StatisticsItemPersonDescTitle>
                    <StatisticsItemPersonDesc>
                      83% kobiet nie nie ma świadomości niealkoholowego
                      stłuszczenia wątroby
                    </StatisticsItemPersonDesc>
                  </StatisticsItemPersonColDesc>
                </StatisticsItemRow>
              </StatisticsItemPerson>
              <StatisticsItemPerson xs={12} md={6} side={'right'}>
                <StatisticsItemRow>
                  <StatisticsItemPersonColPercent xs={6} side={'left'}>
                    <StatisticsItemPersonPercentWrapper>
                      <StatisticsItemPersonPercentAmount>
                        90%
                      </StatisticsItemPersonPercentAmount>
                      <StatisticsItemPersonPercentDesc>
                        mężczyzn
                      </StatisticsItemPersonPercentDesc>
                    </StatisticsItemPersonPercentWrapper>
                  </StatisticsItemPersonColPercent>
                  <StatisticsItemColImage xs={6} side={'right'}>
                    <StatisticsItemImageBGWrapper side={'right'}>
                      <StatisticsItemImageBG
                        fluid={data.Photo2.childImageSharp.fluid}
                        alt=""
                        backgroundColor={`transparent`}
                      />
                    </StatisticsItemImageBGWrapper>
                  </StatisticsItemColImage>
                  <StatisticsItemPersonColDesc xs={12} side={'right'}>
                    <StatisticsItemPersonDescTitle>
                      <strong>Ernest</strong> 26 lat
                    </StatisticsItemPersonDescTitle>
                    <StatisticsItemPersonDesc>
                      90% mężczyzn nie ma świadomości niealkoholowego
                      stłuszczenia wątroby
                    </StatisticsItemPersonDesc>
                  </StatisticsItemPersonColDesc>
                </StatisticsItemRow>
              </StatisticsItemPerson>
            </StatisticsItemWrapper>

            <StatisticsItemWrapper position={'middle'}>
              <StatisticsItemTitleWrapper xs={12}>
                <StatisticsItemTitle>
                  Ze względu <br /> na WIEK:
                </StatisticsItemTitle>
              </StatisticsItemTitleWrapper>
              <StatisticsItemPerson xs={12} md={6} side={'left'}>
                <StatisticsItemRow side={'left'}>
                  <StatisticsItemColImage xs={6} side={'left'}>
                    <StatisticsItemImageBGWrapper side={'left'}>
                      <StatisticsItemImageBG
                        fluid={data.Photo3.childImageSharp.fluid}
                        alt=""
                        backgroundColor={`transparent`}
                      />
                    </StatisticsItemImageBGWrapper>
                  </StatisticsItemColImage>
                  <StatisticsItemPersonColPercent xs={6} side={'right'}>
                    <StatisticsItemPersonPercentWrapper side={'right'}>
                      <StatisticsItemPersonPercentAmount>
                        84%
                      </StatisticsItemPersonPercentAmount>
                      <StatisticsItemPersonPercentDesc>
                        > 50 lat
                      </StatisticsItemPersonPercentDesc>
                    </StatisticsItemPersonPercentWrapper>
                  </StatisticsItemPersonColPercent>
                  <StatisticsItemPersonColDesc xs={12} side={'left'}>
                    <StatisticsItemPersonDescTitle>
                      <strong>Marek</strong> 62 lata
                    </StatisticsItemPersonDescTitle>
                    <StatisticsItemPersonDesc>
                      84% osób powyżej 50 roku życia nie ma świadomości
                      niealkoholowego stłuszczenia wątroby
                    </StatisticsItemPersonDesc>
                  </StatisticsItemPersonColDesc>
                </StatisticsItemRow>
              </StatisticsItemPerson>
              <StatisticsItemPerson xs={12} md={6} side={'right'}>
                <StatisticsItemRow>
                  <StatisticsItemPersonColPercent xs={6} side={'left'}>
                    <StatisticsItemPersonPercentWrapper>
                      <StatisticsItemPersonPercentAmount>
                        89%
                      </StatisticsItemPersonPercentAmount>
                      <StatisticsItemPersonPercentDesc>
                        20-29 lat
                      </StatisticsItemPersonPercentDesc>
                    </StatisticsItemPersonPercentWrapper>
                  </StatisticsItemPersonColPercent>
                  <StatisticsItemColImage xs={6} side={'right'}>
                    <StatisticsItemImageBGWrapper side={'right'}>
                      <StatisticsItemImageBG
                        fluid={data.Photo4.childImageSharp.fluid}
                        alt=""
                        backgroundColor={`transparent`}
                      />
                    </StatisticsItemImageBGWrapper>
                  </StatisticsItemColImage>
                  <StatisticsItemPersonColDesc xs={12} side={'right'}>
                    <StatisticsItemPersonDescTitle>
                      <strong>Alicja</strong> 27 lat
                    </StatisticsItemPersonDescTitle>
                    <StatisticsItemPersonDesc>
                      89% osób w wieku 20-29 lat nie ma świadomości
                      niealkoholowego stłuszczenia wątroby
                    </StatisticsItemPersonDesc>
                  </StatisticsItemPersonColDesc>
                </StatisticsItemRow>
              </StatisticsItemPerson>
            </StatisticsItemWrapper>

            <StatisticsItemWrapper position={'last'}>
              <StatisticsItemTitleWrapper xs={12}>
                <StatisticsItemTitle>
                  Ze względu <br /> na wagę:
                </StatisticsItemTitle>
              </StatisticsItemTitleWrapper>
              <StatisticsItemPerson xs={12} md={6} side={'left'}>
                <StatisticsItemRow side={'left'}>
                  <StatisticsItemColImage xs={6} side={'left'}>
                    <StatisticsItemImageBGWrapper side={'left'}>
                      <StatisticsItemImageBG
                        fluid={data.Photo5.childImageSharp.fluid}
                        alt=""
                        backgroundColor={`transparent`}
                      />
                    </StatisticsItemImageBGWrapper>
                  </StatisticsItemColImage>
                  <StatisticsItemPersonColPercent xs={6} side={'right'}>
                    <StatisticsItemPersonPercentWrapper side={'right'}>
                      <StatisticsItemPersonPercentAmount>
                        84%
                      </StatisticsItemPersonPercentAmount>
                      <StatisticsItemPersonPercentDesc>
                        osób otyłych
                      </StatisticsItemPersonPercentDesc>
                    </StatisticsItemPersonPercentWrapper>
                  </StatisticsItemPersonColPercent>
                  <StatisticsItemPersonColDesc xs={12} side={'left'}>
                    <StatisticsItemPersonDescTitle>
                      <strong>Ryszard</strong> 56 lat
                    </StatisticsItemPersonDescTitle>
                    <StatisticsItemPersonDesc>
                      84% osób z otyłością nie ma świadomości niealkoholowego
                      stłuszczenia wątroby. BMI > 30
                    </StatisticsItemPersonDesc>
                  </StatisticsItemPersonColDesc>
                </StatisticsItemRow>
              </StatisticsItemPerson>
              <StatisticsItemPerson xs={12} md={6} side={'right'}>
                <StatisticsItemRow>
                  <StatisticsItemPersonColPercent xs={6} side={'left'}>
                    <StatisticsItemPersonPercentWrapper>
                      <StatisticsItemPersonPercentAmount>
                        86%
                      </StatisticsItemPersonPercentAmount>
                      <StatisticsItemPersonPercentDesc>
                        <small>
                          osób z wagą <br /> w normie <br />{' '}
                          <strong>BMI</strong> (18,5 - 24,9)
                        </small>
                      </StatisticsItemPersonPercentDesc>
                    </StatisticsItemPersonPercentWrapper>
                  </StatisticsItemPersonColPercent>
                  <StatisticsItemColImage xs={6} side={'right'}>
                    <StatisticsItemImageBGWrapper side={'right'}>
                      <StatisticsItemImageBG
                        fluid={data.Photo6.childImageSharp.fluid}
                        alt=""
                        backgroundColor={`transparent`}
                      />
                    </StatisticsItemImageBGWrapper>
                  </StatisticsItemColImage>
                  <StatisticsItemPersonColDesc xs={12} side={'right'}>
                    <StatisticsItemPersonDescTitle>
                      <strong>Viola </strong> 48 lat
                    </StatisticsItemPersonDescTitle>
                    <StatisticsItemPersonDesc>
                      86% osób z wagą w normie nie ma świadomości
                      niealkoholowego stłuszczenia wątroby. <br /> BMI (18,5 –
                      24,9)
                    </StatisticsItemPersonDesc>
                  </StatisticsItemPersonColDesc>
                </StatisticsItemRow>
              </StatisticsItemPerson>
            </StatisticsItemWrapper>

            <StatisticsCauses>
              <StatisticsCausesImage xs={12} md={4}>
                <Img
                  fluid={data.QuestionIcon.childImageSharp.fluid}
                  alt=""
                  backgroundColor={`transparent`}
                />
              </StatisticsCausesImage>
              <StatisticsCausesDesc xs={12} md={8}>
                <StatisticsCausesDescTitle>
                  Przyczyny/ryzyko zachorowania
                </StatisticsCausesDescTitle>
                <p>
                  Na świecie obserwuje się wzrost zachorowalności na
                  niealkoholowe stłuszczenie wątroby. W Polsce przyjmuje się, że
                  aż ok. 20-25% społeczeństwa, czyli{' '}
                  <strong>ok. 9 mln ludzi</strong>, choruje na niealkoholowe
                  stłuszczenie wątroby.
                </p>
                <p className="titleList">
                  Występowanie tej choroby jest w znacznym stopniu związane
                  m.in. z:
                </p>
                <p className="list">-&nbsp;&nbsp;epidemią otyłości,</p>
                <p className="list">-&nbsp;&nbsp;nadciśnieniem tętniczym,</p>
                <p className="list">-&nbsp;&nbsp;cukrzycą typu drugiego</p>
                <p className="list">-&nbsp;&nbsp;zespołem metabolicznym.</p>
                <p>
                  Wśród osób z NAFLD powszechnie występuje również
                  hiperlipidemia.
                </p>
              </StatisticsCausesDesc>
            </StatisticsCauses>
          </Col>
        </StatisticsWrapper>

        <StatisticsNote>
          <StatisticsNoteDesc>
            <p className="list">
              1. Hartleb M.,
              https://www.termedia.pl/nafld/Prof-Marek-Hartleb-Stluszczenie-watroby-wystepuje-u-20-25-proc-Polakow,31922.html,
              data wejścia 14.11.2019
            </p>
            <p className="list">
              2. Raport z badania metodą CAPI realizowanego przez Ipsos Sp. z
              o.o “Polacy, a niealkoholowe stłuszczenie wątroby”.
              Reprezentatywna próba Polaków w wieku 15+; N=1006. Realizacja
              12-17.09.2019
            </p>
            <p className="list">
              3. Kargulewicz A, et al. Niealkoholowa stłuszczeniowa choroba
              wątroby etiopatogeneza, epidemiologia, leczenie. Nowiny Lekarskie
              2010;79(5):410-418.
            </p>
            <p>
              Essentiale Forte, 300 mg, kapsułki. 1 kapsułka zawiera: 300 mg
              (Phospholipidum essentiale) fosfolipidów z nasion sojowych
              zawierających (3-sn-fosfatydylo)cholinę. Substancje pomocnicze o
              znanym działaniu: olej sojowy, etanol. Wskazania do stosowania:
              Essentiale Forte jest roślinnym produktem leczniczym stosowanym w
              chorobach wątroby. Zmniejsza subiektywne dolegliwości, takie jak:
              brak apetytu, uczucie ucisku w nadbrzuszu spowodowane uszkodzeniem
              wątroby w wyniku nieprawidłowej diety, działania substancji
              toksycznych lub w przebiegu zapalenia wątroby. Przeciwwskazania:
              Nadwrażliwość na substancję czynną, soję, orzeszki ziemne lub na
              którąkolwiek substancję pomocniczą. Podmiot odpowiedzialny:
              Sanofi-Aventis Sp. z o.o. {'{'}ChPL 02/2019{'}'}{' '}
              <span>
                Przed użyciem zapoznaj się z ulotką, która zawiera wskazania,
                przeciwwskazania, dane dotyczące działań niepożądanych i
                dawkowanie oraz informacje dotyczące stosowania produktu
                leczniczego, bądź skonsultuj się z lekarzem lub farmaceutą, gdyż
                każdy lek niewłaściwie stosowany zagraża Twojemu życiu lub
                zdrowiu.
              </span>
            </p>
          </StatisticsNoteDesc>
        </StatisticsNote>
      </StatisticsContainer>
    </StatisticsSection>
  )
}

export default Statistics
