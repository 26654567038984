import styled from 'styled-components'
import { Container } from 'reactstrap'

import ArrowBottomIcon from '../images/arrow_bottom.svg'

export const TopContainer = styled(Container)`
  padding-left: 250px;
  padding-right: 250px;
  @media (min-width: 1200px) and (max-width: 1599px) {
    padding-left: 150px;
    padding-right: 150px;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    padding-left: 120px;
    padding-right: 120px;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    padding-left: 60px;
    padding-right: 60px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    padding-left: 35px;
    padding-right: 35px;
  }
  @media (max-width: 575px) {
    padding-left: 25px;
    padding-right: 25px;
  }
`

export const ArrowBottom = styled.div`
  display: inline-block;
  width: 42px;
  height: 50px;
  background-image: url(${ArrowBottomIcon});
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  @media (min-width: 1200px) and (max-width: 1599px) {
    width: 26px;
    height: 28px;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    width: 26px;
    height: 28px;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    width: 28px;
    height: 32px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    width: 26px;
    height: 30px;
  }
  @media (max-width: 575px) {
    width: 20px;
    height: 24px;
  }
`
