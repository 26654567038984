import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'

import Header from '../components/header'
import Video from '../components/video'
import Statistics from '../components/statistics'
import Footer from '../components/footer'

const IndexPage = () => (
  <Layout>
    <SEO title="Co Polacy wiedzą o niealkoholowym stłuszczeniu wątroby?" />
    <Header />
    <Video />
    <Statistics />
    <Footer />
  </Layout>
)

export default IndexPage
