import React from 'react'
import styled from 'styled-components'
import { Row, Col } from 'reactstrap'
import { Link as LinkScroll } from 'react-scroll'

import { TopContainer } from './tags'

const FooterSection = styled.section``

const FooterContainer = styled(TopContainer)`
  position: relative;
  background-color: ${(props) => props.theme.colorPeachGray};
  padding-top: 40px;
  padding-left: 170px;
  padding-right: 170px;
  padding-bottom: 50px;
  box-shadow: 0px 16px 58px 0px rgba(104, 45, 25, 0.13);
  @media (min-width: 1200px) and (max-width: 1599px) {
    padding-top: 30px;
    padding-left: 120px;
    padding-right: 120px;
    padding-bottom: 35px;
    box-shadow: 0px 10px 36px 0px rgba(104, 45, 25, 0.13);
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    padding-top: 25px;
    padding-left: 110px;
    padding-right: 110px;
    padding-bottom: 30px;
    box-shadow: 0px 10px 36px 0px rgba(104, 45, 25, 0.13);
  }
  @media (min-width: 768px) and (max-width: 991px) {
    padding-top: 20px;
    padding-left: 70px;
    padding-right: 70px;
    padding-bottom: 20px;
    box-shadow: 0px 10px 36px 0px rgba(104, 45, 25, 0.13);
  }
  @media (min-width: 576px) and (max-width: 767px) {
    padding-top: 50px;
    padding-left: 35px;
    padding-right: 35px;
    padding-bottom: 60px;
    box-shadow: 0px 10px 36px 0px rgba(104, 45, 25, 0.13);
  }
  @media (max-width: 575px) {
    padding-top: 40px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 40px;
    box-shadow: 0px 10px 36px 0px rgba(104, 45, 25, 0.13);
  }
`

const FooterRow = styled(Row)``

const FooterColCopyright = styled(Col)``

const FooterColCopyrightDesc = styled.p`
  font-size: 17px;
  font-weight: 700;
  @media (min-width: 1200px) and (max-width: 1599px) {
    font-size: 12px;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    font-size: 12px;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    font-size: 9px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    font-size: 18px;
    margin-bottom: 20px;
  }
  @media (max-width: 575px) {
    font-size: 13px;
    margin-bottom: 20px;
  }
  @media (max-width: 340px) {
    font-size: 11px;
  }
  span {
    display: block;
  }
`

const FooterColLinks = styled(Col)`
  @media (min-width: 768px) {
    text-align: right;
  }
`

const FooterColLinksList = styled.ul`
  display: inline-block;
  padding-left: 0;
`

const FooterColLinksListItem = styled.li`
  display: inline-block;
  padding-left: 15px;
  padding-right: 15px;
  @media (min-width: 1200px) and (max-width: 1599px) {
    padding-left: 10px;
    padding-right: 10px;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    padding-left: 8px;
    padding-right: 8px;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    padding-left: 5px;
    padding-right: 5px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    padding-left: 8px;
    padding-right: 8px;
  }
  @media (max-width: 575px) {
    padding-left: 5px;
    padding-right: 5px;
  }
  &:last-of-type {
    padding-right: 0;
    &:after {
      content: none;
    }
  }
  &:after {
    content: '|';
    position: relative;
    right: -15px;
    @media (min-width: 1200px) and (max-width: 1599px) {
      right: -10px;
    }
    @media (min-width: 992px) and (max-width: 1199px) {
      right: -8px;
    }
    @media (min-width: 768px) and (max-width: 991px) {
      right: -5px;
      font-size: 12px;
    }
    @media (min-width: 576px) and (max-width: 767px) {
      right: -5px;
    }
    @media (max-width: 575px) {
      right: -5px;
      font-size: 13px;
    }
    @media (max-width: 340px) {
      font-size: 12px;
    }
  }
`

const FooterColLinksListItemLink = styled.a`
  color: ${(props) => props.theme.colorBlack};
  font-size: 15px;
  @media (min-width: 1200px) and (max-width: 1599px) {
    font-size: 13px;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    font-size: 12px;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    font-size: 9px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    font-size: 16px;
  }
  @media (max-width: 575px) {
    font-size: 12px;
  }
  @media (max-width: 340px) {
    font-size: 11px;
  }
  &:hover,
  &:focus {
    color: ${(props) => props.theme.colorBlack};
  }
`

const FooterColLinksListItemLinkScroll = FooterColLinksListItemLink.withComponent(
  LinkScroll
)

const Footer = (props) => {
  return (
    <FooterSection>
      <FooterContainer>
        <FooterRow>
          <FooterColCopyright xs={12} md={6}>
            <FooterColCopyrightDesc>
              Badanie świadomościowe{' '}
              <span>„Polacy, a niealkoholowe stłuszczenie wątroby”</span>
            </FooterColCopyrightDesc>
          </FooterColCopyright>
          <FooterColLinks>
            <FooterColLinksList>
              <FooterColLinksListItem>
                <FooterColLinksListItemLink
                  href="http://newsletter.sanofi-aventis.pl/cookies/POLITYKA-PRYWATNOSCI-ENSI-21-05-2018-Sanofi.pdf"
                  target="_blank"
                  title="Polityka prywatności"
                >
                  Polityka prywatności
                </FooterColLinksListItemLink>
              </FooterColLinksListItem>
              <FooterColLinksListItem xs={12} md={6}>
                <FooterColLinksListItemLinkScroll
                  href="/"
                  to="toVideo"
                  smooth
                  offset={-50}
                  title="Sprawdź wyniki"
                >
                  Sprawdź wyniki
                </FooterColLinksListItemLinkScroll>
              </FooterColLinksListItem>
              <FooterColLinksListItem>
                <FooterColLinksListItemLink
                  href="http://www.essentiale.pl/kontakt"
                  target="_blank"
                  title="Kontakt"
                >
                  Kontakt
                </FooterColLinksListItemLink>
              </FooterColLinksListItem>
            </FooterColLinksList>
          </FooterColLinks>
        </FooterRow>
      </FooterContainer>
    </FooterSection>
  )
}

export default Footer
