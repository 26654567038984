import React, { useState, useEffect } from 'react'
import Img from 'gatsby-image'
import BackgroundImage from 'gatsby-background-image'
import { useStaticQuery, graphql } from 'gatsby'
import { Link as LinkScroll } from 'react-scroll'
import styled from 'styled-components'
import { Row, Col } from 'reactstrap'

import { TopContainer, ArrowBottom } from './tags'

const HeaderSection = styled.section``

const HeaderContainer = styled(TopContainer)`
  position: relative;
  padding-bottom: 100px;
  @media (min-width: 1200px) and (max-width: 1599px) {
    padding-bottom: 65px;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    padding-bottom: 50px;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    padding-bottom: 60px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    padding-bottom: 45px;
  }
  @media (max-width: 575px) {
    padding-bottom: 40px;
  }
`

const HeaderBGWrapper = styled(BackgroundImage)`
  display: block;
  height: 100%;
  position: absolute !important;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-position-y: top;
`

const HeaderRow = styled(Row)``

const HeaderColLogo = styled(Col)`
  padding-top: 100px;
  @media (min-width: 1200px) and (max-width: 1599px) {
    padding-top: 65px;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    padding-top: 60px;
  }
  @media (min-width: 992px) {
    display: flex;
    align-items: center;
    justify-content: left;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    padding-top: 70px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    padding-top: 60px;
  }
  @media (max-width: 575px) {
    padding-top: 30px;
  }
`

const HeaderLogoTitle = styled.span`
  display: inline-block;
  /* font-family: ${(props) => props.theme.fontIntro}; */
  color: ${(props) => props.theme.colorWhite};
  font-size: 14px;
  padding-right: 20px;
  @media (min-width: 1200px) and (max-width: 1599px) {
    font-size: 9px;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    font-size: 10px;
  }
  @media (max-width: 991px) {
    display: block;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    font-size: 14px;
    margin-bottom: 5px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    font-size: 15px;
    margin-bottom: 4px;
  }
  @media (max-width: 575px) {
    font-size: 9px;
    margin-bottom: 3px;
  }
`

const HeaderLogoWrapper = styled.a`
  display: inline-block;
  width: 160px;
  @media (min-width: 1200px) and (max-width: 1599px) {
    width: 100px;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    width: 110px;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    width: 200px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    width: 210px;
  }
  @media (max-width: 575px) {
    width: 140px;
  }
`

const HeaderColDesc = styled(Col)`
  padding-right: 35%;
  @media (min-width: 1200px) and (max-width: 1599px) {
    padding-right: 52%;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    padding-right: 45%;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    padding-right: 10%;
  }
  @media (max-width: 767px) {
    padding-right: 15px;
  }
`

const HeaderTitle = styled.h1`
  color: ${(props) => props.theme.colorWhite};
  font-size: 52px;
  font-weight: 700;
  margin-top: 70px;
  @media (min-width: 1200px) and (max-width: 1599px) {
    font-size: 32px;
    margin-top: 45px;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    font-size: 30px;
    margin-top: 40px;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    font-size: 35px;
    margin-top: 60px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    font-size: 32px;
    margin-top: 45px;
  }
  @media (max-width: 575px) {
    font-size: 22px;
    margin-top: 35px;
  }
  span {
    color: ${(props) => props.theme.colorOrange};
  }
`

const HeaderDesc = styled.p`
  color: ${(props) => props.theme.colorWhite};
  font-size: 22px;
  margin-top: 70px;
  @media (min-width: 1200px) and (max-width: 1599px) {
    font-size: 14px;
    margin-top: 40px;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    font-size: 14px;
    margin-top: 35px;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    font-size: 18px;
    margin-top: 25px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    font-size: 19px;
    margin-top: 35px;
  }
  @media (max-width: 575px) {
    font-size: 12px;
    margin-top: 20px;
  }
`

const HeaderReportLink = styled(LinkScroll)`
  display: inline-block;
  background-color: rgb(255, 180, 0);
  color: ${(props) => props.theme.colorWhite};
  font-size: 24px;
  font-weight: 700;
  padding-top: 12px;
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 12px;
  margin-top: 60px;
  border-radius: 16px;
  box-shadow: 0px 16px 40px 0px rgba(68, 11, 0, 0.15);
  @media (min-width: 1200px) and (max-width: 1599px) {
    font-size: 15px;
    padding-top: 10px;
    padding-left: 25px;
    padding-right: 25px;
    padding-bottom: 10px;
    margin-top: 40px;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    font-size: 14px;
    padding-top: 9px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 9px;
    margin-top: 35px;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    font-size: 22px;
    padding-top: 12px;
    padding-left: 35px;
    padding-right: 35px;
    padding-bottom: 12px;
    margin-top: 20px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    font-size: 20px;
    padding-top: 12px;
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 12px;
    margin-top: 20px;
  }
  @media (max-width: 575px) {
    font-size: 12px;
    padding-top: 12px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 12px;
    margin-top: 18px;
  }
  &:hover,
  &:focus {
    color: ${(props) => props.theme.colorWhite};
  }
`

const HeaderColResults = styled(Col)`
  text-align: center;
`

const HeaderResultsTitle = styled.p`
  color: ${(props) => props.theme.colorWhite};
  font-size: 36px;
  font-weight: 700;
  margin-top: 110px;
  margin-bottom: 30px;
  @media (min-width: 1200px) and (max-width: 1599px) {
    font-size: 22px;
    margin-top: 70px;
    margin-bottom: 20px;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    font-size: 20px;
    margin-top: 50px;
    margin-bottom: 20px;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    font-size: 26px;
    margin-top: 50px;
    margin-bottom: 20px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    font-size: 24px;
    margin-top: 50px;
    margin-bottom: 20px;
  }
  @media (max-width: 575px) {
    font-size: 12px;
    margin-top: 30px;
    margin-bottom: 15px;
  }
`

const Header = (props) => {
  const data = useStaticQuery(graphql`
    query HeaderQuery {
      HeaderLogo: file(relativePath: { regex: "/logo.png/" }) {
        childImageSharp {
          fluid(maxWidth: 200, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      HeaderBG: file(relativePath: { regex: "/header_bg.jpg/" }) {
        childImageSharp {
          fluid(maxWidth: 1600, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      HeaderBGMobile: file(relativePath: { regex: "/header_bg_mobile.jpg/" }) {
        childImageSharp {
          fluid(maxWidth: 960, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  const [isMobile, setIsMobile] = useState(false)
  const [flagInit, setFlagInit] = useState(false)

  const setMobile = () => {
    if (window !== 'undefined') {
      if (window.innerWidth <= 991) {
        setIsMobile(!isMobile)
      }
    }
  }
  const setFirstInit = () => setFlagInit(true)

  useEffect(() => {
    if (flagInit === false) {
      setMobile()
      setFirstInit()
    }
  })

  return (
    <HeaderSection>
      <HeaderContainer>
        <HeaderBGWrapper
          Tag={`div`}
          fluid={
            !isMobile
              ? data.HeaderBG.childImageSharp.fluid
              : data.HeaderBGMobile.childImageSharp.fluid
          }
          backgroundColor={`transparent`}
        />
        <HeaderRow>
          <HeaderColLogo xs={12}>
            <HeaderLogoTitle>Inicjator badania:</HeaderLogoTitle>
            <HeaderLogoWrapper href="http://www.essentiale.pl/" target="_blank">
              <Img
                fluid={data.HeaderLogo.childImageSharp.fluid}
                alt="Logo"
                backgroundColor={`transparent`}
              />
            </HeaderLogoWrapper>
          </HeaderColLogo>
          <HeaderColDesc xs={12}>
            <HeaderTitle>
              <span>Co Polacy wiedzą</span> <br /> <span>o</span> niealkoholowym{' '}
              <br /> stłuszczeniu wątroby?
            </HeaderTitle>
            <HeaderDesc>
              <strong>Przeprowadziliśmy badanie świadomościowe</strong> <br />{' '}
              dotyczące roli wątroby w organizmie człowieka. <br /> Zapytaliśmy
              Polaków, czy zdają sobie sprawę z niemego zagrożenia, jakim jest
              stłuszczenie.
            </HeaderDesc>
            <HeaderReportLink href="/" to="toVideo" smooth offset={-50}>
              Raport z badania
            </HeaderReportLink>
          </HeaderColDesc>
          <HeaderColResults xs={12}>
            <LinkScroll href="/" to="toVideo" smooth offset={-50}>
              <HeaderResultsTitle>Zobacz wyniki badania</HeaderResultsTitle>
              <ArrowBottom />
            </LinkScroll>
          </HeaderColResults>
        </HeaderRow>
      </HeaderContainer>
    </HeaderSection>
  )
}

export default Header
