import React, { useState } from 'react'
import BackgroundImage from 'gatsby-background-image'
import { useStaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
import ReactPlayer from 'react-player'
import { Row, Col } from 'reactstrap'

import { TopContainer } from './tags'

import YouTubbePlayerBtnIcon from '../images/youtube_btn.svg'

const VideoSection = styled.section``

const VideoContainer = styled(TopContainer)`
  position: relative;
  background-color: ${(props) => props.theme.colorWhite};
  padding-bottom: 80px;
  box-shadow: 0px 16px 58px 0px rgba(104, 45, 25, 0.13);
  @media (min-width: 1200px) and (max-width: 1599px) {
    padding-bottom: 50px;
    box-shadow: 0px 10px 36px 0px rgba(104, 45, 25, 0.13);
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    padding-bottom: 45px;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    padding-bottom: 35px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    padding-bottom: 25px;
  }
  @media (max-width: 575px) {
    padding-bottom: 25px;
  }
`

const VideoPlayerWrapper = styled.div`
  position: relative;
  height: 580px;
  box-shadow: 0px 0px 18px 2px rgba(15, 7, 8, 0.17);
  margin-top: -80px;
  @media (min-width: 1200px) and (max-width: 1599px) {
    height: 480px;
    margin-top: -60px;
    box-shadow: 0px 0px 6px 1px rgba(15, 7, 8, 0.17);
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    height: 420px;
    margin-top: -45px;
    box-shadow: 0px 0px 5px 1px rgba(15, 7, 8, 0.17);
  }
  @media (min-width: 768px) and (max-width: 991px) {
    height: 380px;
    margin-top: -45px;
    box-shadow: 0px 0px 12px 2px rgba(15, 7, 8, 0.17);
  }
  @media (min-width: 576px) and (max-width: 767px) {
    height: 350px;
    margin-top: -35px;
    box-shadow: 0px 0px 10px 2px rgba(15, 7, 8, 0.17);
  }
  @media (max-width: 575px) {
    height: 240px;
    margin-top: -35px;
    box-shadow: 0px 0px 8px 2px rgba(15, 7, 8, 0.17);
  }
`

const VideoPosterWrapper = styled.div`
  display: ${(props) => (props.isPlaying === true ? 'none' : 'block')};
`

const VideoPoster = styled(BackgroundImage)`
  position: absolute !important;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  text-align: center;
  z-index: 100;
`

const VideoPlayerBtn = styled.div`
  display: inline-block;
  width: 310px;
  height: 254px;
  position: relative;
  top: 50%;
  background-image: url(${YouTubbePlayerBtnIcon});
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  margin-top: -127px;
  cursor: pointer;
  @media (min-width: 1200px) and (max-width: 1599px) {
    width: 190px;
    height: 158px;
    margin-top: -79px;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    width: 160px;
    height: 130px;
    margin-top: -65px;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    width: 150px;
    height: 120px;
    margin-top: -60px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    width: 140px;
    height: 110px;
    margin-top: -55px;
  }
  @media (max-width: 575px) {
    width: 130px;
    height: 110px;
    margin-top: -55px;
  }
`

const VideoPlayerDesc = styled.p`
  font-size: 28px;
  text-align: center;
  /* padding-left: 23%;
  padding-right: 23%; */
  margin-top: 50px;
  @media (min-width: 1200px) and (max-width: 1599px) {
    font-size: 18px;
    /* padding-left: 25%;
    padding-right: 25%; */
    margin-top: 35px;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    font-size: 20px;
    /* padding-left: 22%;
    padding-right: 22%; */
    margin-top: 25px;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    font-size: 18px;
    /* padding-left: 18.5%;
    padding-right: 18.5%; */
    margin-top: 20px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    font-size: 16px;
    margin-top: 20px;
  }
  @media (max-width: 575px) {
    font-size: 14px;
    margin-top: 20px;
  }
  strong {
    color: ${(props) => props.theme.colorOrange};
    font-size: 29px;
    @media (min-width: 1200px) and (max-width: 1599px) {
      font-size: 24px;
    }
    @media (min-width: 992px) and (max-width: 1199px) {
      font-size: 25px;
    }
    @media (min-width: 768px) and (max-width: 991px) {
      font-size: 22px;
    }
    @media (min-width: 576px) and (max-width: 767px) {
      font-size: 20px;
    }
    @media (max-width: 575px) {
      font-size: 18px;
    }
  }
`

const Video = (props) => {
  const data = useStaticQuery(graphql`
    query VideoQuery {
      VideoPoster: file(relativePath: { regex: "/video_poster.png/" }) {
        childImageSharp {
          fluid(maxWidth: 1010, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  const [isPlaying, setPlaying] = useState(false)

  const tooglePlaying = () => setPlaying(!isPlaying)

  return (
    <VideoSection>
      <VideoContainer>
        <Row>
          <Col>
            <VideoPlayerWrapper className="player-wrapper" id="toVideo">
              <ReactPlayer
                className="react-player"
                url="https://www.youtube.com/watch?v=oOZyYXt6s-M"
                width="100%"
                height="100%"
                config={{
                  youtube: {
                    playerVars: {
                      hl: 'pl',
                      loop: 1,
                      modestbranding: 1,
                      rel: 0,
                      controls: 1,
                      showinfo: 0,
                      poster: null,
                    },
                  },
                }}
                playing={isPlaying}
                onEnded={tooglePlaying}
              />
              <VideoPosterWrapper onClick={tooglePlaying} isPlaying={isPlaying}>
                <VideoPoster
                  Tag="div"
                  className={'poster'}
                  fluid={data.VideoPoster.childImageSharp.fluid}
                  backgroundColor={`transparent`}
                >
                  <VideoPlayerBtn />
                </VideoPoster>
              </VideoPosterWrapper>
            </VideoPlayerWrapper>
            <VideoPlayerDesc>
              Nielkoholowe stłuszczenie wątroby <br /> dotyka obecnie nawet{' '}
              <strong>6 mln Polaków</strong>
            </VideoPlayerDesc>
          </Col>
        </Row>
      </VideoContainer>
    </VideoSection>
  )
}

export default Video
